import { Navigate, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import SearchView from "./views/SearchView";
import Home from "./views/Home";
import ParishSelect from "./views/ParishSelect";
import EntityProvider from "./context/EntityContext";

const AppRoutes = ({ entity }) => {

    const routes = [
        {
            path: "/",
            element: <ParishSelect />,
            layout: true
        },
        //{
        //    path: "/parish/:entityName",
        //    element: <SearchView />,
        //    layout: true
        //},
        {
            path: "/parish/:entityName",
            element: entity.collectCondensed === 'Y' ? <Home /> : <SearchView />,
            layout: true
        },
        {
            path: "*",
            element: <Navigate to="/" />,
        },
    ];

    return (
        //<EntityProvider>
            <Routes>
                {routes.map((route, index) => {
                    const { path, element, layout } = route;
                    const RouteComponent = layout ? (
                        <Route key={index} path={path} element={<Layout condensed={entity.collectCondensed}>{element}</Layout>} />
                    ) : (
                        <Route key={index} path={path} element={element} />
                    );
                    return RouteComponent;
                })}
            </Routes>
        //</EntityProvider>
    );
};

export default AppRoutes;