import React, { useState, useEffect } from 'react';

import { Box, Paper, TextField, Button, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import TermsModal from '../modals/TermsModal';
import { commaMoneyFormat, stateOptions } from '../../utils';

const YourInformation = (props) => {
    const {
        payments,
        handleFieldChange,
        handleNext,
        state,
        citationNumber,
        setCitationNumber,
        courtDate,
        setCourtDate,
        amount,
        setAmount,
        fee,
        setFee,
        total,
        setTotal,
        setPayments
    } = props;

    const [international, setInternational] = useState(false);
    const [paymentErrors, setPaymentErrors] = useState({});
    const [infoErrors, setInfoErrors] = useState({});
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    //const [modal, setModal] = useState(false);

    //const sessionData = sessionStorage.getItem(window.location.hostname);
    //let isAgreed = false;

    //if (sessionData) {
    //    const { value, expires } = JSON.parse(sessionData);
    //    if (expires > new Date().getTime()) {
    //        isAgreed = value;
    //    } else {
    //        // If session has expired, remove it from session storage
    //        sessionStorage.removeItem(window.location.hostname);
    //    }
    //}

    //// Update the modal state based on the session storage value
    //useEffect(() => {
    //    setModal(!isAgreed);
    //}, [isAgreed]);

    const addPayment = () => {
        if (citationNumber && courtDate && amount) {
            const serviceFee = amount * 0.05;
            const newPayment = {
                description: 'Citation Payment',
                id: citationNumber,
                serviceFee: serviceFee,
                amount: amount,
                courtDate: courtDate
            }

            setFee(parseFloat(fee) + parseFloat(serviceFee));
            setTotal(parseFloat(total) + parseFloat(amount));
            setPayments([...payments, newPayment])
            setCitationNumber('');
            setAmount('');
            setCourtDate('');
        } else {
            let errors = {};
            if (!citationNumber) {
                errors = { ...errors, citationNumber: 'Required' }
            }
            if (!courtDate) {
                errors = { ...errors, courtDate: 'Required' }
            }
            if (!amount) {
                errors = { ...errors, amount: 'Required' }
            }
            setPaymentErrors(errors);
        }
    }

    const deletePayment = (idx) => {
        let newPayments = payments.filter((p, index) => index !== idx)
        setPayments(newPayments);
        setFee(parseFloat(fee) - parseFloat(payments[idx].serviceFee));
        setTotal(parseFloat(total) - parseFloat(payments[idx].amount));
    };

    const handleSubmit = () => {
        if (payments.length > 0) {
            if (international) {
                if (state.firstName !== '' && state.lastName !== '' && state.address1 !== '' && state.zipCode !== '' && state.city !== '' && state.state !== '' && state.phone !== '' && state.birthDate !== '' && state.country !== '') {
                    handleNext();
                } else {
                    let errors = {};
                    if (!state.firstName) {
                        errors = { ...errors, firstName: 'Required' }
                    }
                    if (!state.lastName) {
                        errors = { ...errors, lastName: 'Required' }
                    }
                    if (!state.address1) {
                        errors = { ...errors, address1: 'Required' }
                    }
                    if (!state.zipCode) {
                        errors = { ...errors, zipCode: 'Required' }
                    }
                    if (!state.city) {
                        errors = { ...errors, city: 'Required' }
                    }
                    if (!state.state) {
                        errors = { ...errors, state: 'Required' }
                    }
                    if (!state.phone) {
                        errors = { ...errors, phone: 'Required' }
                    }
                    if (!state.birthDate) {
                        errors = { ...errors, birthDate: 'Required' }
                    }
                    if (!state.country) {
                        errors = { ...errors, country: 'Required' }
                    }
                    setInfoErrors(errors);
                }
            } else {
                if (state.firstName !== '' && state.lastName !== '' && state.address1 !== '' && state.zipCode !== '' && state.city !== '' && state.state !== '' && state.phone !== '' && state.birthDate !== '') {
                    handleNext();
                } else {
                    let errors = {};
                    if (!state.firstName) {
                        errors = { ...errors, firstName: 'Required' }
                    }
                    if (!state.lastName) {
                        errors = { ...errors, lastName: 'Required' }
                    }
                    if (!state.address1) {
                        errors = { ...errors, address1: 'Required' }
                    }
                    if (!state.zipCode) {
                        errors = { ...errors, zipCode: 'Required' }
                    }
                    if (!state.city) {
                        errors = { ...errors, city: 'Required' }
                    }
                    if (!state.state) {
                        errors = { ...errors, state: 'Required' }
                    }
                    if (!state.phone) {
                        errors = { ...errors, phone: 'Required' }
                    }
                    if (!state.birthDate) {
                        errors = { ...errors, birthDate: 'Required' }
                    }
                    setInfoErrors(errors);
                }
            }
        } else {
            addPayment();
        }
    }

    //const handleTermsAgreement = () => {
    //    const expirationTime = new Date().getTime() + 3600000; // 1 hour in milliseconds
    //    sessionStorage.setItem(window.location.hostname, JSON.stringify({ value: true, expires: expirationTime }));
    //    setModal(false);
    //};

    return (
        <Box
        >
            <Box alignItems={isSmallScreen ? "center" : null} display={isSmallScreen ? 'flex' : null} flexDirection={isSmallScreen ? "column" : null} sx={{
                maxWidth: isSmallScreen ? '95vw' : null, // Adjust the width as needed
                textAlign: isSmallScreen ? 'center' : null, // Center text content
                m: !isSmallScreen ? '2vh 2rem' : null,
            }}>
                <h4>Important Information</h4>
                <p>There is a&nbsp;
                    <span
                        style={
                            {
                                fontWeight: 'bold',
                                fontSize: '1.5rem'
                            }
                        }
                    >
                        SERVICE FEE&nbsp;
                    </span>
                    in the amount of&nbsp;
                    <span
                        style={
                            {
                                color: 'red',
                                fontWeight: 'bolder',
                                fontSize: '1.5rem'
                            }
                        }
                    >
                        5.00%
                    </span>
                    &nbsp;for processing payment(s) online or over the phone. The total charge amount will be displayed prior to submitting the payment.
                </p>
                <Box sx={{ textAlign: 'center', my: '3vh' }}>
                    <h6 style={{ fontWeight: 'bold', margin: '1vh' }}>NO PARTIAL PAYMENTS WILL BE ACCEPTED. THEY WILL BE DECLINED.</h6>
                    <p style={{ marginBottom: '1vh' }}>If you are looking for citation information, call the DA's office at: 318-872-2991.</p>
                    <p>Make sure payment <span style={{ fontWeight: 'bold' }}>DOES NOT</span> belong to Mansfield Police Department!</p>
                </Box>
                {!isSmallScreen && payments.length > 0 && (
                    <Box sx={{ my: '2vh' }}>
                        <Box mb='1vh'>
                            <h4>Saved Information</h4>
                        </Box>
                        <Box>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="right">Service Fee</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payments.map((p, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>Citation Payment</TableCell>
                                            <TableCell>{p.id}</TableCell>
                                            <TableCell align="right">{commaMoneyFormat(p.serviceFee)}</TableCell>
                                            <TableCell align="right">{commaMoneyFormat(p.amount)}</TableCell>
                                            <TableCell onClick={() => deletePayment(idx)} sx={{ cursor: 'pointer' }} align="center"><DeleteIcon /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={2}>Total:</TableCell>
                                        <TableCell align="right">{commaMoneyFormat(fee)}</TableCell>
                                        <TableCell align="right">{commaMoneyFormat(total)}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: isSmallScreen ? 'space-around' : 'space-between',
                        flexDirection: isSmallScreen ? 'column' : 'row'
                    }}
                >
                    <Paper sx={{ width: isSmallScreen ? '98%' : '45%', height: '50%', alignSelf: isSmallScreen ? 'center' : null }} elevation={10}>
                        <Box sx={{ p: '2vh' }}>
                            <h5 style={{ marginBottom: '1vh' }}>Citation Payment</h5>
                            <Box sx={{ textAlign: 'center', my: '2vh' }}>
                                <Box>
                                    <a href='https://issfiles.blob.core.windows.net/securecollect/citation.png' target='_blank' rel='noreferrer'>Where can I find my citation number?</a>
                                </Box>
                                <br />
                                <Box>
                                    <a href='https://issfiles.blob.core.windows.net/securecollect/finesheet.pdf' target='_blank' rel='noreferrer'>Where can I find my fine amount?</a>
                                </Box>
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    id="citationNumber"
                                    label="Citation Number"
                                    variant="standard"
                                    value={citationNumber}
                                    onChange={(e) => setCitationNumber(e.target.value)}
                                    onBlur={(e) => {
                                        if (!e.target.value) {
                                            setPaymentErrors({ ...paymentErrors, citationNumber: 'Required' })
                                        } else {
                                            setPaymentErrors({ ...paymentErrors, citationNumber: null })
                                        }
                                    }}
                                    helperText={paymentErrors?.citationNumber}
                                    error={!!paymentErrors?.citationNumber}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    id="courtDate"
                                    label="Court Date"
                                    variant="standard"
                                    type='date'
                                    sx={{ width: isSmallScreen ? '44vw' : null }}
                                    value={courtDate}
                                    onChange={(e) => setCourtDate(e.target.value)}
                                    onBlur={(e) => {
                                        if (!e.target.value) {
                                            setPaymentErrors({ ...paymentErrors, courtDate: 'Required' })
                                        } else {
                                            setPaymentErrors({ ...paymentErrors, courtDate: null })
                                        }
                                    }}
                                    helperText={paymentErrors?.courtDate}
                                    error={!!paymentErrors?.courtDate}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    id="amount"
                                    label="Amount"
                                    variant="standard"
                                    type='number'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    onBlur={(e) => {
                                        if (!e.target.value) {
                                            setPaymentErrors({ ...paymentErrors, amount: 'Required' })
                                        } else {
                                            setPaymentErrors({ ...paymentErrors, amount: null })
                                        }
                                    }}
                                    helperText={paymentErrors?.amount}
                                    error={!!paymentErrors?.amount}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} my='3vh' mb='1vh'>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={addPayment}>Save and add another</Button>
                            </Box>
                        </Box>
                    </Paper>
                    {isSmallScreen && payments.length > 0 && (
                        <Box sx={{ my: '2vh', width: '100%', alignSelf: 'center' }}>
                            <Box mb='1vh'>
                                <h4>Saved Information</h4>
                            </Box>
                            <Box>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">Service Fee</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="center">Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payments.map((p, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>Citation Payment</TableCell>
                                                <TableCell>{p.id}</TableCell>
                                                <TableCell align="right">{commaMoneyFormat(p.serviceFee)}</TableCell>
                                                <TableCell align="right">{commaMoneyFormat(p.amount)}</TableCell>
                                                <TableCell onClick={() => deletePayment(idx)} sx={{ cursor: 'pointer' }} align="center"><DeleteIcon /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={2}>Total:</TableCell>
                                            <TableCell align="right">{commaMoneyFormat(fee)}</TableCell>
                                            <TableCell align="right">{commaMoneyFormat(total)}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Box>
                        </Box>
                    )}
                    <Paper sx={{ width: isSmallScreen ? '98%' : '45%', mt: isSmallScreen ? '1vh' : null, alignSelf: isSmallScreen ? 'center' : null }} elevation={10}>
                        <Box sx={{ p: '2vh' }}>
                            <h5 style={{ marginBottom: '1vh' }}>Citation Holder's Information</h5>
                            <p>Please enter the following information about the person for whom you are making a payment.</p>
                            <Box my='2vh'>
                                <FormControlLabel control={<Checkbox checked={international} onChange={() => setInternational(!international)} />} label="International Address" />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="firstName"
                                    label="First Name"
                                    variant="standard"
                                    value={state.firstName}
                                    onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                    helperText={infoErrors?.firstName}
                                    error={!!infoErrors?.firstName}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="lastName"
                                    label="Last Name"
                                    variant="standard"
                                    value={state.lastName}
                                    onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                    helperText={infoErrors?.lastName}
                                    error={!!infoErrors?.lastName}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="address1"
                                    label="Address 1"
                                    variant="standard"
                                    value={state.address1}
                                    onChange={(e) => handleFieldChange('address1', e.target.value)}
                                    helperText={infoErrors?.address1}
                                    error={!!infoErrors?.address1}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="address2"
                                    label="Address 2"
                                    variant="standard"
                                    value={state.address2}
                                    onChange={(e) => handleFieldChange('address2', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="zipCode"
                                    label="Zip Code"
                                    variant="standard"
                                    type='number'
                                    value={state.zipCode}
                                    onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                                    helperText={infoErrors?.zipCode}
                                    error={!!infoErrors?.zipCode}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="city"
                                    label="City"
                                    variant="standard"
                                    value={state.city}
                                    onChange={(e) => handleFieldChange('city', e.target.value)}
                                    helperText={infoErrors?.city}
                                    error={!!infoErrors?.city}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    select
                                    sx={{ width: '100%' }}
                                    id="state"
                                    label="State/Territory"
                                    variant="standard"
                                    value={state.state}
                                    onChange={(e) => handleFieldChange('state', e.target.value)}
                                    helperText={infoErrors?.state}
                                    error={!!infoErrors?.state}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '10em',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {stateOptions.map((state, i) => (
                                        <MenuItem key={state.value + i} value={state.value}>{state.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            {international && (
                                <Box my='1vh'>
                                    <TextField
                                        required
                                        sx={{ width: '100%' }}
                                        id="country"
                                        label="Country"
                                        variant="standard"
                                        value={state.country}
                                        onChange={(e) => handleFieldChange('country', e.target.value)}
                                        helperText={infoErrors?.country}
                                        error={!!infoErrors?.country}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            )}
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="phoneNumber"
                                    label="Phone"
                                    variant="standard"
                                    value={state.phone}
                                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                                    helperText={infoErrors?.phone}
                                    error={!!infoErrors?.phone}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="birthDate"
                                    label="Date of Birth"
                                    variant="standard"
                                    type='date'
                                    value={state.birthDate}
                                    onChange={(e) => handleFieldChange('birthDate', e.target.value)}
                                    helperText={infoErrors?.birthDate}
                                    error={!!infoErrors?.birthDate}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} my='3vh' mb='1vh'>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleSubmit}>Save and continue</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            {/*<Modal open={modal} sx={{ width: '75%', m: '5vh auto' }}>*/}
            {/*    <>*/}
            {/*        <TermsModal*/}
            {/*            setModal={setModal}*/}
            {/*            handleSubmit={handleTermsAgreement}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*</Modal>*/}
        </Box>
    )
}

export default YourInformation