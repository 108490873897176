import React from 'react';

import { Box, MenuItem, TextField, Paper } from '@mui/material';
import { formatCreditCardNumber, monthOptions, stateOptions } from '../../../../utils';

const CCInfo = (props) => {
    const {
        state,
        handleFieldChange,
        handleBlur,
        displayCreditCardNumber,
        setDisplayCreditCardNumber,
        ts
    } = props;

    return (
        <Box sx={{ p: '1vh', pt: 0 }}>
            <Paper sx={{ p: '1vh' }} elevation={10}>
                <Box sx={{ p: '1vh', mb: '1vh' }}>
                    <h1 style={{ fontSize: '1.5rem', margin: '0' }}>Credit Card Information</h1>
                    <p style={{ fontSize: '1rem', color: 'gray', margin: 0 }}>Enter your credit card information</p>
                </Box>
                <Box sx={{ mb: '2vh', display: 'flex', gap: '2rem' }}>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="firstName"
                        id="firstName"
                        label="First Name*"
                        placeholder="Enter the first name as it appears on your card"
                        value={state?.firstName}
                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                        onBlur={(e) => handleBlur('firstName', e.target.value)}
                        helperText={state?.errors?.firstName}
                        error={!!state?.errors?.firstName}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        sx={ts}
                        name="lastName"
                        id="lastName"
                        label="Last Name*"
                        placeholder="Enter your last name as it appears on your card"
                        value={state?.lastName}
                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                        onBlur={(e) => handleBlur('lastName', e.target.value)}
                        helperText={state?.errors?.lastName}
                        error={!!state?.errors?.lastName}
                        variant="outlined"
                    />
                </Box>
                <Box mb={2} display={'flex'}>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="creditCardNumber"
                        id="creditCardNumber"
                        label="Credit Card Number*"
                        placeholder="0000 0000 0000 0000"
                        value={displayCreditCardNumber}
                        onChange={(e) => {
                            const newCreditCardNumber = formatCreditCardNumber(e.target.value);
                            setDisplayCreditCardNumber(newCreditCardNumber);
                            handleFieldChange('creditCardNumber', newCreditCardNumber);
                        }}
                        onBlur={(e) => handleBlur('creditCardNumber', e.target.value)}
                        helperText={state?.errors?.creditCardNumber}
                        error={!!state?.errors?.creditCardNumber}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ mb: '2vh', gap: '2rem' }} display={'flex'}>
                    <TextField
                        select
                        fullWidth
                        sx={ts}
                        id="month"
                        name="month"
                        label="Month*"
                        value={state?.month}
                        onChange={(e) => handleFieldChange('month', e.target.value)}
                        onBlur={(e) => handleBlur('month', e.target.value)}
                        helperText={state?.errors?.month}
                        error={!!state?.errors?.month}
                        variant="outlined"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: '20em',
                                    },
                                },
                            },
                        }}
                    >
                        {monthOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="year"
                        id="year"
                        label="Year*"
                        value={state?.year}
                        onChange={(e) => handleFieldChange('year', e.target.value)}
                        onBlur={(e) => handleBlur('year', e.target.value)}
                        helperText={state?.errors?.year}
                        error={!!state?.errors?.year}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        sx={ts}
                        name="securityCode"
                        id="securityCode"
                        label="CVV/CVC*"
                        placeholder="123"
                        value={state?.securityCode}
                        onChange={(e) => handleFieldChange('securityCode', e.target.value)}
                        onBlur={(e) => handleBlur('securityCode', e.target.value)}
                        helperText={state?.errors?.securityCode}
                        error={!!state?.errors?.securityCode}
                        variant="outlined"
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="billingAddress"
                        id="billingAddress"
                        label="Billing Address*"
                        placeholder="ex: 1230 Main Street"
                        value={state?.billingAddress}
                        onChange={(e) => handleFieldChange('billingAddress', e.target.value)}
                        onBlur={(e) => handleBlur('billingAddress', e.target.value)}
                        helperText={state?.errors?.billingAddress}
                        error={!!state?.errors?.billingAddress}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ mb: '2vh', display: 'flex', gap: '2rem' }}>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="city"
                        id="city"
                        label="City*"
                        placeholder="City"
                        value={state?.city}
                        onChange={(e) => handleFieldChange('city', e.target.value)}
                        onBlur={(e) => handleBlur('city', e.target.value)}
                        helperText={state?.errors?.city}
                        error={!!state?.errors?.city}
                        variant="outlined"
                    />
                    <TextField
                        select
                        fullWidth
                        sx={ts}
                        name="state"
                        id="state"
                        label="State*"
                        value={state?.state}
                        onChange={(e) => handleFieldChange('state', e.target.value)}
                        onBlur={(e) => handleBlur('state', e.target.value)}
                        helperText={state?.errors?.state}
                        error={!!state?.errors?.state}
                        variant="outlined"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: '20em',
                                    },
                                },
                            },
                        }}
                    >
                        {stateOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        sx={ts}
                        name="zipCode"
                        id="zipCode"
                        label="Zip Code*"
                        placeholder="Zip"
                        value={state?.zipCode}
                        onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                        onBlur={(e) => handleBlur('zipCode', e.target.value)}
                        helperText={state?.errors?.zipCode}
                        error={!!state?.errors?.zipCode}
                        variant="outlined"
                    />
                </Box>
            </Paper>
        </Box>
    );
};

export default CCInfo;
