import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Paper, Modal, Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast } from 'react-toastify';

import TermsModal from '../components/modals/TermsModal';

import OuachitaImage from '../images/OuachitaSheriffsOfficePNG.png';
import LafayetteImage from '../images/LafayetteSheriffsOfficePNG.png';
import WestFelicianaImage from '../images/WestFelicianaSheriffsOfficePNG.png';
import DesotoImage from '../images/DesotoSheriffsOfficePNG.png';
import GrantImage from '../images/GrantSheriffsOfficePNG.png';


const ParishSelect = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [selectedParish, setSelectedParish] = useState(null);
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState();

    const parishes = [
        {
            name: 'Ouachita',
            fullName: "Ouachita Parish Sheriff's Office",
            image: OuachitaImage
        },
        {
            name: 'West Feliciana',
            fullName: "West Feliciana Parish Sheriff's Office",
            image: WestFelicianaImage
        },
        {
            name: 'Lafayette',
            fullName: "Lafayette Parish Sheriff's Office",
            image: LafayetteImage
        },
        {
            name: 'Desoto',
            fullName: "Desoto Parish Sheriff's Office",
            image: DesotoImage
        },
        {
            name: 'Grant',
            fullName: "Grant Parish Sheriff's Office",
            image: GrantImage
        },
    ];

    const handleClick = (parish) => {
        const sessionToken = sessionStorage.getItem(parish);

        if (!sessionToken) {
            setSelectedParish(parish);
            setModal(!modal)
        } else {
            navigate(`parish/${parish}`);
        }
    };

    const handleSubmit = () => {
        if (selectedParish) {
            sessionStorage.setItem(selectedParish, true);
            navigate(`parish/${selectedParish}`);
        } else {
            toast.error(`Error while trying to accept terms`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleDecline = () => {
        navigate('/');
        setModal(false);
    }

    if (window.location.hostname.startsWith('desoto')) {
        return window.location.replace("https://desoto.securefines.com/parish/Desoto%20Parish%20Sheriff's%20Office");
    }

    if (window.location.hostname.startsWith('grant')) {
        return window.location.replace("https://grant.securefines.com/parish/Grant%20Parish%20Sheriff's%20Office");
    };

    const options = parishes.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }).map(p => p.name);

    return (
        <>
            <Box sx={{ width: 'auto', m: 'auto', display: 'flex', gap: '5rem', flexDirection: isSmallScreen ? 'column' : 'row' }}>
                {isSmallScreen && <Autocomplete
                    options={options}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        setInputValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => <TextField {...params}
                        onChange={(e) => { setInputValue(e.target.value) }}
                        label="Search Agencies" />}
                >
                </Autocomplete>}
                {inputValue ?
                    parishes.filter(p => p.name.toLowerCase().includes(inputValue.toLowerCase())).map((parish, index) => {
                        return (
                            <div key={index} onClick={() => handleClick(parish.fullName)} style={{ width: 'auto', cursor: 'pointer' }}>
                                <Paper sx={{ textAlign: 'center', height: '35vh', alignItems: 'center' }} elevation={10}>
                                    <Box>
                                        <img src={parish.image} alt={parish.name} style={{ width: '15rem' }} />
                                    </Box>
                                    <Box mt={1}>
                                        <h3>
                                            <strong>
                                                {parish.name}
                                            </strong>
                                        </h3>
                                    </Box>
                                </Paper>
                            </div>
                        )
                    })
                    : parishes.map((parish, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => handleClick(parish.fullName)}
                                style={{
                                    width: isSmallScreen ? 'auto' : '20%',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-evenly'
                                }}>
                                <Paper sx={{ textAlign: 'center', height: '35vh' }} elevation={10}>
                                    <Box>
                                        <img src={parish.image} alt={parish.name} style={{ width: '15rem', height: isSmallScreen ? 'auto' : '25vh' }} />
                                    </Box>
                                    <Box mt={1}>
                                        <h3>
                                            <strong>
                                                {parish.name}
                                            </strong>
                                        </h3>
                                    </Box>
                                </Paper>
                            </div>
                        )
                    })}
                <Modal open={modal} sx={{ width: '75%', m: '2vh auto' }}>
                    <>
                        <TermsModal
                            setModal={setModal}
                            handleSubmit={handleSubmit}
                            handleDecline={handleDecline}
                        />
                    </>
                </Modal>
            </Box>
        </>
    )
};

export default ParishSelect;