import React from 'react';

import { Box } from '@mui/material';

const Receipt = ({ email }) => {

    return (
        <Box sx={{ width: '90%', m: '0 auto', textAlign: 'center' }}>
            <Box sx={{ mt: '3vh' }}>
                <h4>Your Payment Has Been Sent</h4>
            </Box>
            <Box mt='2vh'>
                <p>If this is not the correct organization, or if you pay less than the required amount due, your payment will be rejected/refunded/transferred and you will not have satisfied your obligation.</p>
            </Box>
            <Box mt='5vh'>
                <h4>
                    <strong>The receipt for this payment will be emailed to {email} after the payment is approved.</strong>
                </h4>
            </Box>
        </Box>
    )
}

export default Receipt;