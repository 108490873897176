import React, { useContext, useEffect, useState } from 'react';

import { Box, Card, Modal, Button, TextField, CardContent, CardActions, CardHeader, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { toast } from 'react-toastify';

import PaymentInfo from '../modals/paymentModal/PaymentInfo';
import { EntityContext } from '../../context/EntityContext';

const SearchResults = (props) => {
    const { searchResults, loadingSearch, defendantName, ts, caseId } = props;

    const { entity } = useContext(EntityContext)

    const [modal, setModal] = useState(false);
    const [declinedModal, setDeclinedModal] = useState(false);

    const [payments, setPayments] = useState({});
    const [paymentData, setPaymentData] = useState({});
    const [paymentsArray, setPaymentsArray] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [fee, setFee] = useState(0);

    // state for current tab in payment info popup
    const [activeStep, setActiveStep] = useState(0);

    // goes through the object and pulls all of the payment amounts and adds them all together to get a total before adding the extra fee (subtotal)
    const totalPayments = paymentsArray.reduce((total, item) => {
        const payment = parseFloat(item.payment);
        return total + (isNaN(payment) ? 0 : payment);
    }, 0);

    // sets paymentAmount state for the PaymentAmount column in the database
    useEffect(() => {
        const feeAmount = totalPayments * entity.convenienceFeeRate;
        const roundedFee = parseFloat(feeAmount.toFixed(2));
        setFee(roundedFee);
        setPaymentAmount(totalPayments + roundedFee);
    }, [totalPayments, entity.convenienceFeeRate]);

    const handlePaymentChange = (e, row) => {
        const { value } = e.target;

        setPayments((prevData) => ({
            ...prevData,
            [row.id]: value,
        }));

        setPaymentData((prevData) => ({
            ...prevData,
            [row.id]: {
                ...row,
                payment: parseFloat(value).toFixed(2),
            },
        }));
    };

    // Format the payment amounts with appropriate decimal places
    const formattedTotalPayments = parseFloat(totalPayments).toFixed(2);
    const formattedPaymentAmount = parseFloat(paymentAmount).toFixed(2);

    // takes the paymentData object and converts it to an array of objects
    useEffect(() => {
        const paymentDataArray = Object.values(paymentData);
        setPaymentsArray(paymentDataArray);
    }, [paymentData]);

    const handleSubmit = () => {
        const paymentValueCheck = paymentsArray.every((payment) => payment.payment === 0);

        const overPayCheck = paymentsArray.some(payment => parseFloat(payment.payment) > payment.totalBalance);

        let total = paymentsArray.reduce((total, payment) => {
            return parseFloat(total) + parseFloat(payment.payment)
        }, 0);

        if (paymentValueCheck || total === 0) {
            toast.error("Please input at least one payment amount.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (overPayCheck) {
            toast.error("Violation over payment is not accepted", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            handleOpen();
        }
    };

    const handleOpen = () => setModal(true);
    const handleClose = () => {
        setModal(false);
        setActiveStep(0);
    };

    const decline = () => setDeclinedModal(true);
    const closeDecline = () => setDeclinedModal(false);

    // function for 'next' button to allow the user to go to the next tab in payment info popup
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // function for 'back' button to allow the user to go to the previous tab in payment info popup
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // table/data-grid columns
    const columns = [
        {
            field: 'currentStatute',
            headerName: 'Statute',
            width: 115
        },
        {
            field: 'description',
            headerName: "Title",
            width: 250
        },
        {
            field: 'totalFines',
            headerName: 'Fines',
            width: 115,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            width: 115,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`
        },
        {
            field: 'totalBalance',
            headerName: 'Total Due',
            width: 115,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`
        },
        {
            field: 'payment',
            headerName: 'Payment Today',
            width: 125,
            renderCell: (params) => {
                const row = params.row;
                const paymentValue = payments[row.id] || '';

                return (
                    <TextField
                        fullWidth
                        sx={ts}
                        id={`payment-${row.id}`}
                        placeholder="####.##"
                        value={paymentValue}
                        onChange={(e) => handlePaymentChange(e, row)}
                    />
                );
            },
        },
    ];

    return (
        <Card sx={{ width: '55%', m: '1vh auto' }}>
            <CardHeader title="Violations With Outstanding Balances" />
            <CardContent>
                <Box sx={{ height: "35vh", overFlow: "auto" }}>
                    <DataGrid
                        rows={searchResults}
                        columns={columns}
                        loading={loadingSearch}
                        slots={{ loadingOverlay: LinearProgress }}
                        localeText={{ noRowsLabel: <h3>No Results</h3> }}
                        disableRowSelectionOnClick
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                marginTop: "1em",
                                marginBotton: "1em"
                            }
                        }}
                    />
                </Box>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'end', pr: '1.5vh', pb: '1.5vh' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit() }
                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                >
                    Submit Payments
                </Button>
            </CardActions>
            <Modal open={modal} sx={{ width: "50%", margin: "10vh auto" }}>
                <>
                    <PaymentInfo
                        handleClose={handleClose}
                        paymentsArray={paymentsArray}
                        paymentAmount={formattedPaymentAmount}
                        setPaymentAmount={setPaymentAmount}
                        totalPayments={formattedTotalPayments}
                        fee={fee}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        decline={decline}
                        closeDecline={closeDecline}
                        declinedModal={declinedModal}
                        entityId={entity.id}
                        defendantName={defendantName}
                        ts={ts}
                        caseId={caseId}
                    />
                </>
            </Modal>
        </Card>
    )
}

export default SearchResults;