import React from 'react';

import { Box, Button } from '@mui/material';

const DeclinedModal = (props) => {
    const { closeDecline } = props;

    return (
        <Box sx={{ width: '90%', m: '2vh auto', textAlign: 'center' }}>
            <h2 style={{ color: 'white', fontWeight: 'bold' }}>Payment Declined</h2>
            <p style={{ color: 'white', fontWeight: 'bold' }}>Your payment was declined. Please try again.</p>
            <Button onClick={closeDecline} sx={{ backgroundColor: 'black', color: 'white', mt: '8vh' }} variant="contained">Close</Button>
        </Box>
    )
}

export default DeclinedModal;