import React, { useState, useReducer } from 'react';

import { Box, Step, Stepper, StepLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import YourInformation from '../components/steps/yourInformation';
import TermsAgreement from '../components/steps/termsAgreement';
import Payment from '../components/steps/payment';
import Receipt from '../components/steps/receipt';

const steps = ['Your Information', 'Terms Agreement', 'Payment', 'Receipt'];

const Home = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const [payments, setPayments] = useState([]);
    const [citationNumber, setCitationNumber] = useState('');
    const [courtDate, setCourtDate] = useState('');
    const [amount, setAmount] = useState('');
    const [fee, setFee] = useState(0);
    const [total, setTotal] = useState(0);
    const [email, setEmail] = useState('');

    const [activeStep, setActiveStep] = useState(0);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const handleNext = () => { setActiveStep(activeStep + 1); window.scrollTo(0, 0); };
    const handleBack = () => { setActiveStep(activeStep - 1); window.scrollTo(0, 0); };

    const initialState = {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: 'LA',
        country: '',
        phone: '',
        birthDate: ''
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const stepContent = [
        <YourInformation
            payments={payments}
            handleFieldChange={handleFieldChange}
            handleNext={handleNext}
            state={state}
            citationNumber={citationNumber}
            setCitationNumber={setCitationNumber}
            courtDate={courtDate}
            setCourtDate={setCourtDate}
            amount={amount}
            setAmount={setAmount}
            fee={fee}
            setFee={setFee}
            total={total}
            setTotal={setTotal}
            setPayments={setPayments}
        />,
        <TermsAgreement
            handleBack={handleBack}
            handleNext={handleNext}
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
        />,
        <Payment
            payments={payments}
            total={total}
            fee={fee}
            state={state}
            handleBack={handleBack}
            handleNext={handleNext}
            setEmail={setEmail}
        />,
        <Receipt email={email} />
    ];

    const HandleStep = async (label, index) => {
        if (index !== steps.length - 1) {
            if (label === 'Terms Agreement') {
                return payments.length > 0 ? setActiveStep(index) : null;
            }
            if (label === 'Payment') {
                return acceptedTerms === true ? setActiveStep(index) : null;
            }
            setActiveStep(index)
        }
    }

    return (
        <Box
            sx={{
                width: isSmallScreen ? '100%' : '50%',
                display: isSmallScreen ? 'flex' : null,
                alignItems: isSmallScreen ? 'center' : null,
                //flex: isSmallScreen ? 1 : null,
                m: isSmallScreen ? null : '0 auto',
                justifyContent: 'center', // Center horizontally
            }}
        >
            {!isSmallScreen && (
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}
                                onClick={
                                    async () => {
                                        await HandleStep(label, index);
                                    }}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            )}
            <Box>
                {stepContent[activeStep]}
            </Box>
        </Box>
    );
};

export default Home