import React, { useState, createContext, useMemo, useEffect, useContext } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './css/custom.css';
import AppRoutes from './AppRoutes';
import { EntityContext } from './context/EntityContext';

export const ThemeModeContext = createContext({
    mode: 'light',
    toggleMode: () => { }
});

export const apiRoute = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
export const frontendRoute = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE_FRONTEND : process.env.REACT_APP_PRO_MODE;

const App = () => {
    // State to hold the current theme mode
    const [mode, setMode] = useState('light');
    const { entity } = useContext(EntityContext);

    useEffect(() => {
        const savedMode = localStorage.getItem('mode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('mode', mode);
    }, [mode]);

    // Memoized object with the toggle function to update the theme mode
    const toggleMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    // Create the theme object with the current mode
    const theme =
        createTheme({
            palette: {
                mode,
            },
            root: {
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 600,
                        md: 900,
                        lg: 1200,
                        xl: 1536,
                    },
                },
            }
        });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ThemeModeContext.Provider value={{ mode, toggleMode }}>
                <AppRoutes entity={entity} />
            </ThemeModeContext.Provider>
        </ThemeProvider>
    );
}

export default App;