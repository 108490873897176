// function to display a space inbetween each 4 numbers of the credit card #
export const formatCreditCardNumber = (creditCardNumber) => {
    const cleaned = creditCardNumber.replace(/\s/g, ''); // Remove existing spaces
    const match = cleaned.match(/.{1,4}/g); // Match groups of up to 4 characters
    if (match) {
        return match.join(' '); // Join groups with spaces
    }
    return '';
};

export const hideCreditCardNumber = (creditCardNumber) => {
    const maskedNumber = creditCardNumber.replace(/\d{4}(?=\s\d{4})/g, '****');
    return maskedNumber;
};

//* --------------- CODE TO FORMAT PHONE NUMBERS EX: (999) 99-9999 ---------------
export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        const part1 = match[1];
        const part2 = match[2];
        const part3 = match[3];
        let formattedPhoneNumber = '';
        if (part1) {
            formattedPhoneNumber += '(' + part1;
        }
        if (part2) {
            formattedPhoneNumber += ") " + part2;
        }
        if (part3) {
            formattedPhoneNumber += "-" + part3;
        }
        return formattedPhoneNumber;
    }
    return '';
};

// Create a function that removes non-digits
export const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
};

// array of all of the month options for the month dropdown input
export const monthOptions = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
];

// array of all of the state options for the state dropdown input
export const stateOptions = [
    { value: 'AL', label: 'AL' },
    { value: 'AK', label: 'AK' },
    { value: 'AZ', label: 'AZ' },
    { value: 'AR', label: 'AR' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DE', label: 'DE' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'HI', label: 'HI' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'IA', label: 'IA' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'ME', label: 'ME' },
    { value: 'MD', label: 'MD' },
    { value: 'MA', label: 'MA' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MS', label: 'MS' },
    { value: 'MO', label: 'MO' },
    { value: 'MT', label: 'MT' },
    { value: 'NE', label: 'NE' },
    { value: 'NV', label: 'NV' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NY', label: 'NY' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VT', label: 'VT' },
    { value: 'VA', label: 'VA' },
    { value: 'WA', label: 'WA' },
    { value: 'WV', label: 'WV' },
    { value: 'WI', label: 'WI' },
    { value: 'WY', label: 'WY' },
];

export const commaMoneyFormat = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value);
}