import React from 'react';

import { Box, Button, Paper } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const TermsModal = (props) => {
    const { setModal, handleSubmit, handleDecline } = props;

    return (
        <Paper elevation={10}>
            <Paper sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh' }} elevation={10}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', m: '0 auto' }}>
                    <h1 style={{ flex: 1, margin: 0 }}>Terms of Use Agreement</h1>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div onClick={() => setModal(false)}>
                            <h2 style={{ margin: 0, cursor: 'pointer' }}>
                                <CloseIcon sx={{ fontSize: '2rem' }} />
                            </h2>
                        </div>
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ overflow: 'auto' }}>
                <Box sx={{ maxHeight: '80vh', overflowY: 'auto', p: '1vh' }}>
                    <Box mt={2}>
                        <p>To continue to proceed with your payment, you must accept the following TERMS OF USE. Please read and fully understand the following terms and press the {'"ACCEPT TERMS"'} button to acknowledge that you have read and accept these terms. Acceptance of these terms is required to continue to payment. If you do not accept these terms, press {'"DECLINE TERMS"'} button to return to the Payment Search screen.</p>
                    </Box>
                    <Box mt={2}>
                        <p>The systems in place for automated processing of information from the government agency to which you have a obligation for payment have been tested thoroughly and are subject to multiple levels of backup, confirmation and security. By using this automated payment system, USER acknowledges and understands that Interactive Software Solutions s providing this service as an agent of the government agency you are making a payment to for the strict purpose of accepting electronic payments due to the government agency and remitting such funds to the government agency. Interactive Software and Services has no legal authority related to the {"USER's"} obligation to the government agency. By using this system, USER also acknowledged errors may occur just as errors can occur with human processing of information. By using this service, you agree that this is voluntary and that you (the {'"USER"'}) understand that the service fee added to the payment(s) being made are charged by the Provider to pay merchant processing fees, web hosting fees, administration and other costs and expenses associated with providing this service. No part of the service fee benefits the government agency, or, any specific civil service individual or any jurisdiction in which the charges or fees were levied, incurred or are being paid.</p>
                    </Box>
                    <Box mt={2}>
                        <p>Other than in connection with processing the payment being made, to the fullest extent possible, identifying private information will not be distributed in any way. No data records or other information will be used, released or sold to any third party. No information will be released to any other party unless such party requires the information for purposes of processing or providing another service essential to completing the transaction related to the payment(s) being made.
                            Interactive Software Solutions, LLC (the {'"COMPANY"'}) agrees to use all reasonable efforts to provide accurate processing of information provided from files provided by the government agency, and to diligently distribute information provided by the USER to the government agency. Interactive Software Solutions, LLC cannot and does not guarantee the accuracy or timeliness of any provided information provided and expressly disclaim any warranty, including merchantability and fitness for a particular use under the Uniform Commercial Code of Georgia beyond the extent of the service fees paid by the user of the service.</p>
                    </Box>
                    <Box mt={2}>
                        <p>Interactive Software Solutions, LLC has in place a comprehensive security plans and internal control plan which is designed to ensure the anonymity of program user information. Further, access to such information is controlled and restricted to authorized personnel only. The payment submission process uses Secure Sockets Layer (SSL) encryption to virtually eliminate the possibility of unauthorized access to your private information while it is being transferred across the internet. Your personal financial data is NOT stored on computers administered by the Company.
                            By submitting this information electronically, you agree to release the government agency, court, judge, civil service individuals or any jurisdiction in which the charges or fees were levied, and Interactive Software Solutions, LLC, its principals, officers, directors, employees, agents affiliated companies, successors and assignees (collectively the {"'PROVIDERS'"}) from any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected to the use of the Program or with the delay or inability to use it, or for any information, software, products and services obtained through this program, or otherwise arising out of the use of this program, the internet generally, or on any other basis (whether based on contract, tort, strict liability or otherwise.) The Providers will not be responsible for any security breaches or non-compliance with Federal or State law or terms of this agreement which results in any act or omission of the USER or a third party unrelated to the negligence of the Provider.</p>
                    </Box>
                    <Box mt={2}>
                        <p>The operation of this online payment program is based in Baton Rouge, Louisiana and any action of any nature against the company must be brought in Baton Rouge, Louisiana. You agree not to challenge the use of any electronic payment and additionally agree that any action brought by the Provider(s) against you to enforce any electronic payment for which any benefit has been provided to you in any way shall entitle the Provider(s) to per se probable cause for criminal action for theft of services or for civil recovery of all fees paid, process fees, costs, attorney�s fees, plus any incidental or associated damages proven by the Provider(s). Any such civil actions shall be bought in the courts of Baton Rouge, Louisiana, without regard to choose of law, and all parties consent to jurisdiction and venue therein. Nothing herein is to be construed as legal counsel or advice. Users should consult with their own legal counsel with respect to the implications of making the payment through this system.</p>
                    </Box>
                    <Box mt={2}>
                        <p>Any purchases made by a USER from Interactive Software Solutions, LLC via the Interactive Software Solutions web services sites are NON-REFUNDABLE, in whole or in part, once the payment has been submitted, confirmed and accepted by the government agency. If the submission is rejected by the government agency, refunds will be processed via credit card, ACH or paper check. Any processing fees associated with the transaction are NON-REFUNDABLE.
                            To continue to pay, you must accept the following TERMS OF USE. Please read and fully understand the following terms and press the {'"ACCEPT TERMS"'} button to acknowledge that you have read and accept these terms. Acceptance of these terms is required to continue to payment. If you do not accept these terms, press {'"DECLINE TERMS"'} button to return to the Payment Search screen.</p>
                    </Box>
                    <Box mt={2}>
                        <p>Please indicate your consent to these terms by pressing the {"'Accept Terms'"} button.</p>
                    </Box>
                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <Button variant="contained" sx={{ color: 'white', backgroundColor: 'steelblue' }} onClick={handleSubmit}>Accept Terms</Button>
                        <Button variant="contained" sx={{ color: 'white', backgroundColor: 'steelblue' }} onClick={handleDecline}>Decline Terms</Button>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default TermsModal;