import React, { useState, useContext } from 'react';
import axios from 'axios';

import { Box, Paper, TextField, Button, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, MenuItem, Backdrop, CircularProgress, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { apiRoute } from '../../App';
import { monthOptions } from '../../utils';
import { EntityContext } from '../../context/EntityContext';

const Payment = (props) => {
    const { payments, total, fee, state, handleBack, handleNext, setEmail } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const { entity } = useContext(EntityContext);

    const initialBillingState = {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: 'LA',
        country: '',
        phone: '',
        birthDate: '',
        email: '',
        confirmEmail: '',
    };

    const [billingState, setBillingState] = useState(initialBillingState);
    const [international, setInternational] = useState(false);
    const [sameInfo, setSameInfo] = useState(false);
    const [infoErrors, setInfoErrors] = useState({});

    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCvv] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorModal, setErrorModal] = useState(false);

    const handleFieldChange = (field, value) => {
        setBillingState(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleAutoFill = () => {
        setSameInfo(prevSameInfo => !prevSameInfo);
        const value = !sameInfo;
        if (!value) {
            setBillingState((prevState => ({
                ...initialBillingState,
                email: state.email,
                confirmEmail: state.confirmEmail
            })));
        } else {
            setBillingState(state)
        }
    };


    const submitPayment = () => {
        setSubmitDisabled(true);
        setLoading(true);
        let errors = {};
        Object.keys(billingState).forEach(key => {
            if (key !== 'address2' && key !== 'birthDate' && !billingState[key]) {
                errors[key] = `Required`;
            }
        });
        if (!cardNumber) errors = { ...errors, cardNumber: 'Required' }
        if (cardNumber.length > 19 || cardNumber.length < 13) errors = { ...errors, cardNumber: 'Invalid Card Length' }
        if (!cvv) errors = { ...errors, cvv: 'Required' }
        if (!month) errors = { ...errors, month: 'Required' }
        if (!year) errors = { ...errors, year: 'Required' }
        if (international && !billingState.country) {
            errors = { ...errors, country: 'Required' };
        }
        else {
            // country is always required or null, and cannot submit unless it is forcefully deleted
            errors = { ...errors };
            delete errors['country'];
        }
        setInfoErrors(errors);
        if (Object.keys(errors).length === 0) {
            const headers = { 'Content-Type': 'application/json' }

            const dataObject = {
                newPayments: [],
                ccPaymentData: {},
            };

            payments.forEach((p) => {
                dataObject.firstName = sameInfo ? state.firstName : billingState.firstName;
                dataObject.lastName = sameInfo ? state.lastName : billingState.lastName;
                dataObject.CreditCardNumber = cardNumber;
                dataObject.month = month;
                dataObject.year = year;
                dataObject.securityCode = cvv;

                dataObject.newPayments.push({
                    CitNum: p.id,
                    CourtDate: p.courtDate,
                    Amount: parseFloat(p.amount),
                    FirstName: sameInfo ? state.firstName : billingState.firstName,
                    LastName: sameInfo ? state.lastName : billingState.lastName,
                    Address1: sameInfo ? state.address1 : billingState.address1,
                    Address2: sameInfo ? state.address2 : billingState.address2,
                    ZipCode: sameInfo ? parseInt(state.zipCode) : parseInt(billingState.zipCode),
                    City: sameInfo ? state.city : billingState.city,
                    State: sameInfo ? state.state : billingState.state,
                    PhoneNumber: sameInfo ? state.phone : billingState.phone,
                    DOB: new Date(state?.birthDate),
                    Email: billingState.email,
                    ConvenienceFee: p.serviceFee,
                });

                dataObject.ccPaymentData = {
                    CardHolderEmail: billingState.email,
                    CardHolderPhone: sameInfo ? state.phone : billingState.phone,
                    CardHolderName: `${state.firstName} ${state.lastName}`,
                    CardHolderAddressLine1: sameInfo ? state.address1 : billingState.address1,
                    CardHolderCity: state.city,
                    CardHolderState: state.state,
                    CardHolderZip: state.zipCode,
                    PaymentAmount: 0.00,
                    firstName: sameInfo ? state.firstName : billingState.firstName,
                    lastName: sameInfo ? state.lastName : billingState.lastName,
                    CreditCardNumber: cardNumber,
                    month: month,
                    year: year,
                    securityCode: cvv,
                    ConvenienceFee: 0.00,
                    PaymentOffset: 0.00,
                };
            });

            axios.post(`${apiRoute}/api/desotopayment?entityId=${entity.id}`, dataObject)
                .then(({ data }) => {
                    // Handle successful response
                    handleNext();
                    setEmail(billingState.email);
                    setSubmitDisabled(false);
                })
                .catch(err => {
                    // Handle errors and exceptions
                    console.error(err); // Log the error for debugging purposes
                    setLoading(false);
                    setSubmitDisabled(false);
                    if (err.response) {
                        setErrorMessage(err.response.data.split('REFID:')[0]);
                        setErrorModal(true);
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        //toast.error(`Server Error: ${err.response.data}`, {
                        //    position: "top-right",
                        //    autoClose: 200000,
                        //    hideProgressBar: true,
                        //    closeOnClick: true,
                        //    pauseOnHover: true,
                        //    draggable: true,
                        //    progress: undefined,
                        //    theme: "colored",
                        //    // Handle server error
                        //});
                    } else if (err.request) {
                        setErrorMessage('Network Error: No response received from the server')
                        setErrorModal(true);
                        // The request was made but no response was received
                        //toast.error('Network Error: No response received from the server', {
                        //    position: "top-right",
                        //    autoClose: 200000,
                        //    hideProgressBar: true,
                        //    closeOnClick: true,
                        //    pauseOnHover: true,
                        //    draggable: true,
                        //    progress: undefined,
                        //    theme: "colored",
                        //    // Handle network error
                        //});
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        setErrorMessage(err.response.data);
                        setErrorModal(true);
                        //toast.error(`Client Error: ${err.message}`, {
                        //    position: "top-right",
                        //    autoClose: 200000,
                        //    hideProgressBar: true,
                        //    closeOnClick: true,
                        //    pauseOnHover: true,
                        //    draggable: true,
                        //    progress: undefined,
                        //    theme: "colored",
                        //    // Handle client-side error
                        //});
                    }
                })
                .finally(() => { setLoading(false); setSubmitDisabled(false); });
        }
        else {
            setLoading(false);
            setSubmitDisabled(false);
            return toast.error('Please clear error(s) in form(s)', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    return (
        <>
            <Box mt='2vh' p='1vh'>
                <Box mb='3vh'>
                    <h2>Payment</h2>
                </Box>
                <Paper sx={{ p: '1vh' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <h5>You have elected to pay for the following item(s).</h5>
                    </Box>
                    <strong>DeSoto Parish Sheriff's Office</strong>
                    <p style={{ fontSize: '8pt' }}>205 Franklin st., Mansfield, Louisiana 71052</p>
                    {payments.length > 0 && (
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Service Fee</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((p, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>Citation Payment</TableCell>
                                        <TableCell>{p.id}</TableCell>
                                        <TableCell align="right">${parseFloat(p.serviceFee).toFixed(2)}</TableCell>
                                        <TableCell align="right">${parseFloat(p.amount).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={2}>Total:</TableCell>
                                    <TableCell align="right">${parseFloat(fee).toFixed(2)}</TableCell>
                                    <TableCell align="right">${parseFloat(total).toFixed(2)}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '1vh' }}>
                        <h5 style={{ color: 'red', fontWeight: 'bold' }}>Total Amount Due: {new Intl.NumberFormat('en-US', { style: 'currency', currency: "USD", maximumFractionDigits: 2 }).format(parseFloat(total) + parseFloat(fee))}</h5>
                    </Box>
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '3vh' }}>
                    <Paper sx={{ width: '45%' }} elevation={10}>
                        <Box sx={{ p: '2vh' }}>
                            <h5 style={{ marginBottom: '1vh' }}>Billing Information</h5>
                            <Box my='2vh'>
                                <FormControlLabel control={<Checkbox checked={sameInfo} onChange={handleAutoFill} />} label="Same As Previous Information" />
                                <FormControlLabel control={<Checkbox checked={international} onChange={() => setInternational(!international)} />} label="International Address" />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="firstName"
                                    label="First Name"
                                    variant="standard"
                                    value={sameInfo ? state.firstName : billingState.firstName}
                                    onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, firstName: null }) }}
                                    helperText={infoErrors?.firstName}
                                    error={!!infoErrors?.firstName}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="lastName"
                                    label="Last Name"
                                    variant="standard"
                                    value={sameInfo ? state.lastName : billingState.lastName}
                                    onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, lastName: null }) }}
                                    helperText={infoErrors?.lastName}
                                    error={!!infoErrors?.lastName}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="address1"
                                    label="Address 1"
                                    variant="standard"
                                    value={sameInfo ? state.address1 : billingState.address1}
                                    onChange={(e) => handleFieldChange('address1', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, address1: null }) }}
                                    helperText={infoErrors?.address1}
                                    error={!!infoErrors?.address1}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="address2"
                                    label="Address 2"
                                    variant="standard"
                                    value={sameInfo ? state.address2 : billingState.address2}
                                    onChange={(e) => handleFieldChange('address2', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, address2: null }) }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="zipCode"
                                    label="Zip Code"
                                    variant="standard"
                                    type='number'
                                    value={sameInfo ? state.zipCode : billingState.zipCode}
                                    onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, zipCode: null }) }}
                                    helperText={infoErrors?.zipCode}
                                    error={!!infoErrors?.zipCode}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="city"
                                    label="City"
                                    variant="standard"
                                    value={sameInfo ? state.city : billingState.city}
                                    onChange={(e) => handleFieldChange('city', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, city: null }) }}
                                    helperText={infoErrors?.city}
                                    error={!!infoErrors?.city}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="state"
                                    label="State/Territory"
                                    variant="standard"
                                    value={sameInfo ? state.state : billingState.state}
                                    onChange={(e) => handleFieldChange('state', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, state: null }) }}
                                    helperText={infoErrors?.state}
                                    error={!!infoErrors?.state}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            {international && (
                                <Box my='1vh'>
                                    <TextField
                                        required
                                        sx={{ width: '100%' }}
                                        id="country"
                                        label="Country"
                                        variant="standard"
                                        value={sameInfo ? state.country : billingState.country}
                                        onChange={(e) => handleFieldChange('country', e.target.value)}
                                        onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, country: null }) }}
                                        helperText={infoErrors?.country}
                                        error={!!infoErrors?.country}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            )}
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="phoneNumber"
                                    label="Phone"
                                    variant="standard"
                                    value={sameInfo ? state.phone : billingState.phone}
                                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, phone: null }) }}
                                    helperText={infoErrors?.phone}
                                    error={!!infoErrors?.phone}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="email"
                                    label="Email"
                                    variant="standard"
                                    value={billingState.email}
                                    onChange={(e) => handleFieldChange('email', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, email: null }) }}
                                    helperText={infoErrors?.email}
                                    error={!!infoErrors?.email}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='1vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="confirmEmail"
                                    label="Confirm Email"
                                    variant="standard"
                                    value={billingState.confirmEmail}
                                    onChange={(e) => handleFieldChange('confirmEmail', e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, confirmEmail: null }) }}
                                    helperText={infoErrors?.confirmEmail}
                                    error={!!infoErrors?.confirmEmail}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: isSmallScreen ? '50%' : '45%', height: '50%' }} elevation={10}>
                        <Box sx={{ p: '2vh' }}>
                            <h5 style={{ marginBottom: '1vh' }}>Citation Payment</h5>
                            <Box my='2vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="cardNumber"
                                    label="Card Number"
                                    variant="standard"
                                    value={cardNumber}
                                    onChange={(e) => setCardNumber(e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, cardNumber: null }) }}
                                    helperText={infoErrors?.cardNumber}
                                    error={!!infoErrors?.cardNumber}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 19, minLength: 13 }}
                                />
                            </Box>
                            <Box my='2vh'>
                                <TextField
                                    required
                                    sx={{ width: '100%' }}
                                    id="cvvCode"
                                    label="CVV Code"
                                    variant="standard"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, cvv: null }) }}
                                    helperText={infoErrors?.cvv}
                                    error={!!infoErrors?.cvv}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box my='2vh'>
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Box width='50%'>
                                        <TextField
                                            required
                                            select
                                            sx={{ width: '100%' }}
                                            id="expMonth"
                                            label="Month"
                                            variant="standard"
                                            value={month}
                                            onChange={(e) => setMonth(e.target.value)}
                                            onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, month: null }) }}
                                            helperText={infoErrors?.month}
                                            error={!!infoErrors?.month}
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            {monthOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box width='50%'>
                                        <TextField
                                            required
                                            sx={{ width: '65%' }}
                                            placeholder="yy"
                                            id="expYear"
                                            label="Year"
                                            variant="standard"
                                            type='number'
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                            onBlur={(e) => { if (e.target.value) setInfoErrors({ ...infoErrors, year: null }) }}
                                            helperText={infoErrors?.year}
                                            error={!!infoErrors?.year}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 2,
                                                    style: { textAlign: 'right' }
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Paper sx={{ p: '1vh', border: '1.5px solid', mt: '3vh', borderRadius: '5px' }}>
                    <h2>Important Information</h2>
                    <hr />
                    <Box mt='1vh'>
                        <p>Please provide the correct billing address associated with the account being used to make the payment.</p>
                    </Box>
                    <Box mt='1vh'>
                        <p>If this is not the correct organization, or if you pay less than the required amount due, your payment will be rejected/refunded/transferred and you will not have satisfied your obligation.</p>
                    </Box>
                    <Box mt='1vh'>
                        <h6>
                            <strong>To receive an email confirmation of your payment, please include a valid email address.</strong>
                        </h6>
                    </Box>
                    <hr />
                    <Box>
                        <p>Once the organization reviews your payment you will receive an email notifying you of the acceptance OR rejection of your submission.</p>
                    </Box>
                    <hr />
                    <Box>
                        <strong>**Credit Card Disclaimer:**</strong>
                    </Box>
                    <Box>
                        <p>By using this credit card gateway, you acknowledge and agree to be subject to a $35 non-sufficient funds (NSF) or chargeback fee for any failed transactions or disputed charges.</p>
                    </Box>
                </Paper>
                <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" sx={{ color: 'white', backgroundColor: 'steelblue' }} onClick={handleBack}>Previous Page</Button>
                    <Button variant="contained" sx={{ color: 'white', backgroundColor: 'steelblue' }} disabled={submitDisabled} onClick={submitPayment}>Submit Payment</Button>
                </Box>
            </Box>
            {loading && (
                <Backdrop open sx={{ zIndex: 1 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <CircularProgress />
                    </div>
                </Backdrop>
            )}
            {!!errorModal && (
                <Modal open={errorModal}>
                    <Paper sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh', width: '33%', m: 'auto', mt: '25vh' }} elevation={10}>
                        <Box sx={{ display: 'flex', width: '99%' }}>
                            <h1 style={{ marginRight: 'auto'}}>PAYMENT ERROR</h1>
                        </Box>
                        <Paper sx={{ height: 'auto', pt: '1vh' }}>
                        <h3>Transaction Failed</h3>
                            <p>{errorMessage}</p>
                            <Button variant="contained"
                            sx={{ backgroundColor: 'steelblue', color: 'white', mb: '1vh' }} onClick={() => { setErrorModal(false); setErrorMessage(''); }}>Close</Button>
                        </Paper>
                    </Paper>
                </Modal >
            )}
            <ToastContainer />
        </>
    )
}

export default Payment;