import React, { useState, useReducer, useContext } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SearchForm from '../components/search/SearchForm';
import SearchResults from '../components/search/SearchResults';
import { ThemeModeContext } from '../App';

const SearchView = () => {
    const { mode } = useContext(ThemeModeContext);

    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [caseId, setCaseId] = useState(null);

    const initialState = {
        caseCitationNumber: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        driverLicenseNumber: '',
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            <SearchForm
                setSearchResults={setSearchResults}
                setLoadingSearch={setLoadingSearch}
                setFormSubmitted={setFormSubmitted}
                state={state}
                dispatch={dispatch}
                ts={textFieldStyle}
                setCaseId={setCaseId}
            />
            {
                formSubmitted &&
                <SearchResults
                    searchResults={searchResults}
                    loadingSearch={loadingSearch}
                    defendantName={`${state?.firstName} ${state.lastName}`}
                    ts={textFieldStyle}
                    caseId={caseId}
                />
            }
            <ToastContainer />
        </>
    )
}

export default SearchView;